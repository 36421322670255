import Vue from 'vue'
import VueRouter from 'vue-router'

import { detectLogin } from "@/utils/login";

// We use import() to load pages to support code splitting. See
// https://webpack.js.org/guides/code-splitting/#dynamic-imports

const HomePage = () => import('@/views/HomePage');

// LOGIN
const Logout = () => import('@/views/Logout');
const Login = () => import('@/views/Login');

// ERRORS
const Error403 = () => import('@/views/ErrorPages/403');
const Error404 = () => import('@/views/ErrorPages/404');
const Error408 = () => import('@/views/ErrorPages/408');
const Error500 = () => import('@/views/ErrorPages/500');

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    routes: [
        // HOME
        {
            path: '/',
            name: 'home',
            component: HomePage,
            meta: {
                name: "Bar ENSTA Paris",
            }
        },
        // LOGIN
        {
            path: '/login',
            name: 'login',
            component: Login,
            props: true,
            beforeEnter: (to, from, next) => {
                if (to.query.logged_in) {
                    detectLogin().then(() => {
                        return next(to.query.next || '/');
                    }).catch(() => {
                        next();
                    });
                } else {
                    next();
                }
            },
            meta: {
                name: "Connexion"
            }
        },
        {
            path: '/logout',
            name: 'logout',
            component: Logout,
        },
        // ERRORS
        {
            path: '/403',
            name: "403",
            component: Error403,
            meta: {
                name: "Error 403"
            }
        },
        {
            path: '/408',
            name: "408",
            component: Error408,
            meta: {
                name: "Error 408"
            }
        },
        {
            path: '/500',
            name: "500",
            component: Error500,
            meta: {
                name: "Error 500"
            }
        },
        {
            path: "*",
            name: "404",
            component: Error404,
            meta: {
                name: "Error 404"
            }
        }
    ],
});

export default router
