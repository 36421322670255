import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

import router from './routes/routes'

// axios
import axios from 'axios'
import VueAxios from 'vue-axios'
axios.defaults.withCredentials = true
Vue.use(VueAxios, axios)

import AxiosWrappers from "./plugins/axiosWrappers"
Vue.use(AxiosWrappers);

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
