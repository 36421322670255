/*
 * Wrapper around axios to handle classic errors (401, 403)
 */

import semver from 'semver';

function axiosPost(...names) {
    return this.axios.post(...names).catch(err => {
        if (err.response) {
            if (err.response.status == 401) {
                return new Promise(resolve => this.$router.replace(
                    {
                        name: "login",
                        query: { err: err.response.data },
                        params: { to: this.$route.fullPath }
                    }, resolve
                ));
            } else if (err.response.status == 403) {
                return new Promise(resolve => this.$router.replace({ path: "/403" }, resolve));
            } else if (err.response.status == 404) {
                return new Promise(resolve => this.$router.replace({ path: "/404" }, resolve));
            }
        } else if (err.isAxiosError) {
            return new Promise(resolve => this.$router.push({ path: "/408" }, resolve));
        }
        throw err;
    });
}
const AxiosWrappers = {
    install(Vue) {
        if (semver.lt(Vue.version, '3.0.0')) {
            Object.defineProperties(Vue.prototype, {
                axiosPost: {
                    get: function get() {
                        return axiosPost;
                    }
                },
            });
        } else {
            Vue.config.globalProperties.axiosPost = axiosPost;
        }
        Vue.axiosPost = axiosPost;
    }
}

export default AxiosWrappers;